.triangle-img-white {
    position:relative;
    width:100%;
    height:200px;
    background-color: rgb(240, 240, 240);
    clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
}

.diamond-img-white
{
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color:  rgb(240, 240, 240);
    position:relative;
    top: 0;
    bottom:-10px;
}

.diamond-img-white:after {
    content: '';
    position: absolute;
    left: -80px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color:  rgb(240, 240, 240);
}

.mobile-hidden {
    visibility: visible;
}

@media screen and(max-width:800px) {
    .triangle-img-white {
        background-color:red;
    }
    .mobile-hidden {
        visibility:hidden;
        
    }
}