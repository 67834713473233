@import url('https://fonts.googleapis.com/css?family=Playfair Display');
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
    overflow-x: hidden;
}

.home-main {
    background-color: black;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    overflow:hidden;
}

.home-section-1 {
    margin-top: 50px;
    height: 900px;
    background: linear-gradient(to bottom, transparent 30%, black 100%), url("../images/circle-1.png") 50% 35% no-repeat, url("../images/home-section-1-img.png") 50% 70% no-repeat;
    background-repeat: no-repeat;
}

.section-1-title-container {
    font-size: 75px;
    font-weight: 400;
    font-family: "Poppins";
    width: 40%;
    z-index: 1;
    margin-left: 10%;
}

.section-1-body-text {
    font-family: "Poppins";
    width: 30%;
    font-size: 18px;
    line-height: 36px;
    padding: 50px;
    margin-top: 5%;
    margin-left: auto;
    margin-right: 8%;
    background-color: black;
}



.title-p {
    padding: 0px;
    margin: 0;
}

.button-apply {
    min-width: 120px;
    min-height: 40px;
    max-height: 60px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1.3px;
    color: white;
    background-color: #B3863B;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    display: flexbox;
    padding: 0px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 15px;
    padding-top: 15px;
    transition: 3s ease-in-out;
}

.button-apply:hover
{
    background: linear-gradient(90deg, rgba(255,132,0,1) 0%, rgba(255,138,0,1) 39%, rgba(255,215,0,1) 100%);
}

.title-text-italic {
    font-family: "Playfair Display";
    font-weight: 700;
    font-style: italic;
}

.section-2-main {
    background-color: rgb(240, 240, 240);
    width:100%;
}

.section-2-main-inner {
    padding-top: 2%;
    width: 80%;
    margin-left: 10%;
}

.section-2-title {
    font-family: "Playfair Display";
    font-size: 54px;
    font-style: italic;
    font-weight: 600;
    color: black;
}

.section-2-title-1 {
    font-weight: bolder;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins";
}

.section-2-body {
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    color: #595959;
    margin-bottom: 2%;
}

.section-2-features {
    color: black;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    flex-wrap: wrap;
}

.section-2-feature-icon {}

.section-2-features-wrapper
{
    max-width:100%;
    overflow:hidden;
}

.section-2-featurebox {
    background-color: white;
    min-width: 50%;
    flex:1;
}

.section-2-featurebox:hover {
    z-index: 2;
    box-shadow:
        0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02),
        0.7px 0.7px 5.3px rgba(0, 0, 0, 0.028),
        1.3px 1.3px 10px rgba(0, 0, 0, 0.035),
        2.2px 2.2px 17.9px rgba(0, 0, 0, 0.042),
        4.2px 4.2px 33.4px rgba(0, 0, 0, 0.05),
        10px 10px 80px rgba(0, 0, 0, 0.07);
    border-width: 1%;
    min-width: 50%;
    max-width: 50%;
}

.section-2-feature-header {
    font-family: "Poppins";
    font-size: 1.2vw;
    font-weight: 600;
}

.section-2-featurebox-inner {
    margin: 10%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    gap: 10px;
}

.section-2-feature-body {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    gap: 10px;
}

.section-2-feature-desc {
    font-size: 1.2vw;
    font-family: "Poppins";
    color:#595959;
    line-height: 28.8px;
}

.diamond-container {
    width: 100%;
    text-align: center;
    margin-left: 50%;
}


.home-section-3 {
    margin: 0;
    width: 100%;
    min-height: 600px;
    background: linear-gradient(to bottom, transparent 30%, black 100%), url("../images/bg-s3.png") 50% 0 no-repeat;
    background-size: cover;
    overflow-y: hidden;
    overflow:hidden;
}

.home-section-3-title {
    margin-top: 5%;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 56px;
    color: white;
}

.home-section-3-hero {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.home-section-3-hero-inner {
    display: inline-block;
    text-align: center;
    margin-top: 5%;
    font-family: "Playfair Display";
    font-weight: 600;
    font-size: 28px;
    padding: 30px 120px 30px 120px;
    margin: 0;
    margin-top: 5%;
    border-radius: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #B3863B;
    ;
    font-style: italic;
    color: white;
}

.home-section-3-body {
    margin-top: 5%;
    margin-bottom: 5%;
    font-family: "Poppins";
    color: #D7D7D7;
    width: 60%;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    margin-left: 20%;
}

.home-section-3-windows {
    display: flex;
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    height: auto;
    margin-left: 5%;
    margin-bottom: 10%;
}

.home-section-3-windows img {
    flex: 1;
    align-items: center;
    overflow: hidden;
    margin-right: 5%;
    height: 80%;
}

.sec-3-window-img-1 {
    margin-top: 2%;

}

.sec-3-window-img-2 {
    margin-top: 10%;

}

.sec-3-window-img-4 {
    margin-top: 10%;
}


.home-section-4
{
    margin-bottom: 10%;
    width:100vw;
}

.home-section-4-outer
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:80%;
    margin-left:10%;
}

.home-section-4-inner-left
{
    flex:9;    
    margin-right: 10%;
    display: flex;
    flex-direction: column;
}

.home-section-4-inner-right
{
    flex:1;
}

.section-4-text-large
{
    font-family: "Poppins";
    font-weight:600;
    font-size: 46px;
    color:white;
}

.section-4-text-medium
{
    flex:1;
    font-family: "Poppins";
    font-weight:400;
    font-size: 16px;
    color:#D7D7D7;
    max-width:70%;
}

.home-section-4-img
{
   overflow:hidden;
}

.section-4-button-apply
{
    width:25%;
}

.poppins-gold
{
    color:#B3863B
}


@media only screen and (max-width: 768px) {
    .home-section-1 {
        margin-top:100px;
        height: 750px;
        background: linear-gradient(to bottom, transparent 5%, black 60%), url("../images/circle-1.png") 50% 35% / 100% no-repeat, url("../images/home-section-1-img.png") 50% 40% / 100% no-repeat;
        background-repeat: no-repeat;
    }

    .section-1-title-container {
        font-size: 28px;
        font-weight: 400;
        font-family: "Poppins";
        width:80%;
        z-index: 1;
        margin-left: 1%;    
    }

    .section-1-title {
        text-align: center;
    }

    .apply-section-1 {
        display: none;
    }

    .section-1-body-text {
        text-align: center;
        font-family: "Poppins";
        width: 100%;
        font-size: 12px;
        line-height: 36px;
        padding: 50px;
        margin-top: 80%;
        margin-left: auto;
        margin-right: 4%;
        background-color: rgba(0,0,0,0.2);
    }
    

    
.section-2-main {
    background-color: rgb(240, 240, 240);
    width:100%;
}

.section-2-main-inner {
    padding-top: 2%;
    width: 80%;
    margin-left: 10%;
}

.section-2-title {
    text-align: center;
    font-family: "Playfair Display";
    font-size: 48px;
    font-style: italic;
    font-weight: 600;
    color: black;
}

.section-2-title-1 {
    font-weight: bolder;
    font-style: normal;
    font-weight: 600;
    font-family: "Poppins";
}

.section-2-body {
    text-align: center;
    font-size: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    color: #595959;
}

.section-2-features {
    color: black;
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    flex-wrap: wrap;
}

.section-2-feature-icon {}

.section-2-features-wrapper
{
    max-width:100%;
    overflow:hidden;
}

.section-2-featurebox {
    background-color: white;
    min-width: 100%;
    flex:1;
}

.section-2-featurebox:hover {
    z-index: 2;
    box-shadow:
        0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02),
        0.7px 0.7px 5.3px rgba(0, 0, 0, 0.028),
        1.3px 1.3px 10px rgba(0, 0, 0, 0.035),
        2.2px 2.2px 17.9px rgba(0, 0, 0, 0.042),
        4.2px 4.2px 33.4px rgba(0, 0, 0, 0.05),
        10px 10px 80px rgba(0, 0, 0, 0.07);
    border-width: 1%;
    min-width: 50%;
    max-width: 100%
}

.section-2-feature-header {
    font-family: "Poppins";
    font-size: 2vw;
    font-weight: 600;
}

.section-2-featurebox-inner {
    margin: 10%;
}

.section-2-feature-desc {
    font-size: 8px;
    line-height: 14px;
}

.diamond-container {
    width: 100%;
    text-align: center;
    margin-left: 50%;
}


.home-section-3 {
    margin: 0;
    width: 100%;
    min-height: 600px;
    background: linear-gradient(to bottom, transparent 30%, black 100%), url("../images/bg-s3.png") 50% 0 no-repeat;
    background-size: cover;
    overflow-y: hidden;
    overflow:hidden;
}

.home-section-3-title {
    text-align: center;
    margin-top: 15%;
    text-align: center;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 36px;
    color: white;
}

.home-section-3-hero {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.home-section-3-hero-inner {
    display: inline-block;
    text-align: center;
    font-family: "Playfair Display";
    font-weight: 600;
    font-size: 12px;
    padding: 10px 60px 10px 60px;
    margin: 0;
    margin-top: 2%;
    border-radius: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #B3863B;
    ;
    font-style: italic;
    color: white;
}

.home-section-3-body {
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
    font-family: "Poppins";
    color: #D7D7D7;
    width: 90%;
    font-size: 14px;
    font-weight: 400;
    line-height: 28.8px;
    margin-left: 5%;
}


.home-section-4
{
    margin-bottom: 10%;
    width:100vw;
}

.home-section-4-outer
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:90%;
    margin-left:5%;
}

.home-section-4-inner-left
{
    flex:9;    
    margin-right: 0%;
    display: flex;
    flex-direction: column;
}

.home-section-4-inner-right
{
    flex:1;
    display: none;
}

.section-4-text-large
{
    font-family: "Poppins";
    font-weight:600;
    font-size: 22px;
    color:white;
}

.section-4-text-medium
{
    flex:1;
    font-family: "Poppins";
    font-weight:400;
    font-size: 12px;
    color:#D7D7D7;
    max-width:100%;
}

.home-section-4-img
{
   overflow:hidden;
   display: none;
}

.section-4-button-apply
{
    width:25%;
}

.poppins-gold
{
    color:#B3863B
}
}