.list-container {
    display:inline-flex;
    width:20%;    
    flex-direction: column;
    height:85vh;
    border-style:solid;
    border-width:1px;
    justify-content: left;
    border-color:white;
    overflow-y:scroll;
    overflow-x:hidden;
}


.list-table-filter{
    border-bottom-style:solid;
    border-bottom-color:white;
    padding:0;
    margin:0;
}

.list-table-filter-button {
    background: none;
	border: none;
    color:white;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-size:22px;
    margin:0;
}

.list-item-filter-button {
    background: none;
	border: none;
    color:white;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    font-size:16px;
    margin:0;
}

.list-filters {
    border-bottom-style:solid;
    border-bottom-color:white;
}


.dash-nav-list-item {
    display:block;
}

.applicant-container {
    width:100%;
    text-align:left;
    border-bottom: solid 1px grey;
    cursor:pointer;
}

.applicant-link {
    text-decoration:none;
    background-color:none;
    font-size:16px;
   
}