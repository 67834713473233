@import url('https://fonts.googleapis.com/css?family=Playfair Display');
@import url('https://fonts.googleapis.com/css?family=Poppins');

.contact-section-main 
{
    display: block;
    background-color: black;
    width:100%;
}

.contact-section-inner
{
    display: flex;
    flex-direction: row;
    max-width:100%;
    padding-top: 0%;
}

.contact-section-left-column
{
    flex:1;
    background: url("../../images/circle-1.png");
    background-size: 150%;
    background-position: bottom -250px left -200px;
    background-repeat: no-repeat;
    padding-bottom: 20%;
}

.contact-section-middle-column
{
    margin-top: 5%;
    flex:3;
    margin-bottom: 5%;
}

.contact-section-right-column
{
    flex:1;
    background: url("../../images/circle-1.png");
    background-size: 150%;
    background-position: top -150px right -300px;
    background-repeat: no-repeat;
}

.contact-section-title
{
    text-align: center;
    font-family: "Playfair Display";
    font-weight: 700;
    font-style: italic;
    font-size: 75px;
}

.title-poppins
{
    font-weight: 500;
    font-family: "Poppins";
    font-style:normal;
}

.contact-section-desc
{
    width:100%;
    text-align: center;
    font-weight: 400;
    font-family: "Poppins";
    font-style:normal;
    font-size: 16px;
    color:#D7D7D7;
}


.contact-section-form-container
{
    display: flex;    
    margin-top: 10%;
    width:100%;
}

.contact-sent {
    text-align:center;
    font-size:16px;
    color:white;    
}

.form-outer
{
    flex:1;
    width:100%;

}
.form-row-1
{
    display: flex;
    flex-direction: row;
}

.form-row-2
{
    display: flex;
    flex-direction: row;
}

.form-split-row
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 5%;
    width:100%;
    margin-bottom: 5%;
}

.form-full-row
{
    margin-bottom: 5%;
}

.form-half-width
{
    width:50%;
}

.form-input-title
{
    font-size: 16px;
    color:white;
    font-family: 14px;
}
.input-form
{
    line-height: 28px;
    background-color: black;
    font-family: "Poppins";
    border-style:none;
    font-size: 16px;
    color:grey;
    border-bottom: 1px grey solid;
    caret-color: grey;
    outline:none;
    width:100%;
    margin-top: 2%;
}

.form-textarea
{
    resize: none;
}


.contact-preference-text
{
    vertical-align: middle;
    font-family: "Poppins";
    font-weight:400;
    font-size: 16px;
}

.form-input-contact-preference-type
{
    vertical-align: middle;
    font-family: "Poppins";
    font-weight:400;
    font-size: 14px;
}

.preference-checkbox
{
    text-decoration: none;
    height:20px;
    width:20px;
    border-radius: 40px;
    accent-color: grey;
}

.form-input-contact-preference
{
    max-width:100%;
    display: flex;
    direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;
    text-align: end;
}

.form-error-text
{
    color:red;
}


@media screen and (max-width: 800px)
{

    
.contact-section-right-column
{
    flex:1;
    background: url("../../images/circle-1.png");
    background-size: 0%;
    background-position: top 150px right 300px;
    background-repeat: no-repeat;
}

.contact-section-left-column
{
    flex:1;
    background: url("../../images/circle-1.png");
    background-size: 0%;
    background-position: bottom -250px left -200px;
    background-repeat: no-repeat;
    padding-bottom: 20%;
}
    .contact-section-main
    {
        max-width: 100%;
        min-width: none;
    }

    .contact-section-inner
    {
        flex-direction: column;
    flex-shrink:1;
    width: 90%;
        margin-left: 5%;
    }

    .contact-section-title
    {
        font-size: 26px;
    }

    .form-input-contact-preference
    {
        max-width:100%;
        display: flex;
        direction: column;
        justify-content: flex-start;
        align-items: left;
        margin-right: 2%;
        gap: 6px;
        text-align: end;
    }

    .contact-preference-text
{
    vertical-align: middle;
    font-family: "Poppins";
    font-weight:400;
    font-size: 12px;
}
}