.application-form-container {
    text-align:center;
    width:90%;
}

.form-inner {
    display:inline-block;
    vertical-align: top;
    padding: 8px 10px;
    margin-bottom:1%;
    min-width:36%;
    margin-right:2%;
    line-height:36px;
    font-size:16px;
}



.form-error{
    font-size:14px;
    color:red;
    display:block;
}

.form-label{
    font-size:14px;
}

.form-single-line {
    padding: 8px 10px;
    margin-bottom:1%;
    min-width:100%;
    margin-right:2%;
    line-height:18px;
    font-size:16px;
}


.form-inner-social {
    display:inline-flex;
    vertical-align: top;
    padding: 8px 0px;
    margin-bottom:1%;
    min-width:30%;
    flex-direction: column;
    align-items: left;
    margin-right:2%;
    line-height:36px;
    font-size:16px;
}

.form-single-line-social {
    padding: 20px 20px;
    margin-bottom:1%;
    min-width:100%;
    border-style:none;
    line-height:18px;
    font-size:16px;
    text-align:left;
}

.form-single-line-dropdown
{
    padding: 8px 1px;
    margin-bottom:1%;
    min-width:100%;
    line-height:18px;
    font-size:16px;
    text-align:left;
}

.form-multi-line {
    resize:none;
    min-width:100%;
    font-family: "Poppins";
    padding: 20px 20px;
    border-style:none;
    max-width:100%;
    font-size:16px;
}

.mid-width
{
   width:50%;   
}


.app-title-text
{
    color:black;
    font-family: "Poppins";
    font-weight:600;
    font-size: 28px;
}

.form-single-line-social-wide
{
    padding: 20px 20px;
    margin-bottom:1%;
    min-width:100%;
    border-style:none;
    font-family: "Poppins";
    line-height:18px;
    font-size:16px;
    text-align:left;
}

.form-single-line-wide 
{
    padding: 20px 20px;
    margin-bottom:1%;
    min-width:100%;
    font-family: "Poppins";
    border-style:none;
    line-height:18px;
    font-size:16px;
    text-align:left;
}


.form-inner-social-wide
{
    display:flex;
    vertical-align: top;
    padding: 8px 0px;
    font-family: "Poppins";
    margin-bottom:1%;
    min-width:100%;
    flex-direction: column;
    align-items: left;
    margin-right:2%;
    line-height:36px;
    font-size:16px;
}

.form-single-line-social-mid
{
    padding: 20px 10px;
    margin-top: 1%;
    min-width:40%;
    border-style:none;
    font-family: "Poppins";
    line-height:18px;
    font-size:16px;
    text-align:left;
}

.form-section-flex
{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    width:100%;
}

.form-inner-wide {
    display: flex;
    height:auto;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    vertical-align: top;
    margin-bottom:1%;
    min-width:100%;
    line-height:36px;
    font-size:16px;
}

.form-inner-social-wide {
    display:flex;
    vertical-align: top;
    padding: 8px 0px;
    margin-bottom:1%;
    flex:1;
    flex-wrap: wrap;
    min-width:100%;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    line-height:36px;
    font-size:16px;
}

.application-container
{
    padding-bottom: 5%;
}

.app-section-top
{
    height:600px;    
    background: url("../../images/circle-1.png");
    background-repeat: no-repeat;
    background-position: left -300px bottom -300px;
}

.application-title
{
    vertical-align: middle;
    font-family: "Poppins";
    font-weight:600;
    color:white;
    line-height: 400px;
    font-size: 46px;
    margin-left: 10%;
}


.app-form-container
{
    background-color: rgb(240,240,240);
    margin-top: 5%;
}


@media only screen and (max-width: 768px) {
    .form-single-line-social-wide {
        max-width: 80%;
    }

    .form-inner-social-wide{
        max-width: 80%;
    }

    .form-single-line-social-mid {
        max-width: 100%;
    }
    
}