.app-main
{

}

.app-container
{

    background-color: rgb(240,240,240);
}


.app-section-top
{
    background-color: black;
    height:600px;
}

.app-form-container
{
    
    width:80%;
    margin-left: 10%;
}

.preference-checkbox
{
    color:black;
}

.thanks-message {
  color:black;
}

@media only screen and (max-width: 768px) {

  .app-container {
    max-width: 100%;
  }

  
}