.services-section-1
{
    background: url("../../images/services-s1.png");
    background-size: cover;
    width:100%;
    height:800px;
}

.services-section-1-inner
{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

}

.services-title
{
    font-family: "Poppins";
    font-weight: 500;
    font-size: 75px;
    margin-left: 10%;
    margin-top: 10%;
}

.semi-transparent
{
    background-color: rgba(0,0,0,0.6);
}

.services-desc
{
    margin-right: 0;
}

.services-section-2-inner
{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: 10%;
}

.services-section-2
{
    background-color: rgb(240,240,240);
    padding-top: 5%;
    padding-bottom: 10%;
}

.services-section-2-block
{
    display: flex;
    flex-direction: row;
    max-width: 100%;
}

.text-black
{
    color:black;
}

.services-s2-text-body
{
    flex:1;
    margin-top: 5%;
    color: #595959;
    font-size:16px;

}

.services-s2-text-desc
{
    margin-top: 5%;
    display: block;
    flex-direction: column;
    margin-right: 10%;    
    max-width:100%;
    flex-shrink: 3;
}

.services-s2-img-container
{
    flex:1;
    width:50%;
    flex-shrink: 3;
}

.s2-img
{
}

@media screen and (max-width: 800px)
{
  .services-section-2-block
  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
  }

  .services-s2-text-desc
  {
    max-width: 100%;
    flex:1;
    margin-bottom: 10%;
    font-size: 16px;
    padding: 0;
  }

  .services-s2-text-body
  {
    font-size: 8px;;
  }
  .s2-img
  {
    width:64px;
    height: 64px;
    justify-self: center;
  }

  .services-section-1
  {
      background: url("../../images/services-s1.png");
      background-size: cover;
      background-position: -200px;
      background-repeat: no-repeat;
      width:100%;
      height:850px;
  }

  .services-title
  {
    margin-top: 20%;
    font-size: 48px;
  }

  .services-body {
    margin-top:5%;
  }

}
    