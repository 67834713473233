.about-main-container
{
    height:100%;
    background-color: rgb(240, 240, 240);
}

.black
{
    background-color: black;
}

.about-section-one-container
{
display: flex;
background: url("../../images/about-us-hero.png");
background-repeat: no-repeat;
background-size: cover;
background-color: rgba(0,0,0,0.7);
height:1000px;
background-blend-mode: multiply;
flex-direction: column;
justify-content: space-between;
}

.about-section-one-title
{
color:white;
width:90%;
margin-left: 10%;
font-family: "Poppins";
font-size: 48px;
padding-top: 10%;
}


.about-section-one-mask
{
    background-color: green;
    width:100%;
    height:300px;
}

.about-section-2-title {
    margin-top: 5%;
    margin-left: 10%;
    text-align: left;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 56px;
    color: black;
}

.about-section-two-container
{
display: flex;
flex-direction: row;
margin-bottom: 10%;
}

.about-section-two-left
{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    max-width:60%;
}

.about-section-two-right
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
}

.about-section-2-hero {
    display: inline-block;
    margin-left: 10%;
    max-width: 100%;
    text-align: left;
    color:black;
}

.about-section-2-hero-inner {
    display: inline-block;
    text-align: center;
    margin-top: 5%;
    font-family: "Playfair Display";
    font-weight: 600;
    font-size: 28px;
    padding: 30px 120px 30px 120px;
    margin: 0;
    margin-top: 5%;
    border-radius: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #B3863B;
    font-style: italic;
    color: black;
}

.about-section-2-body {
    margin-top: 5%;
    margin-bottom: 5%;
    font-family: "Poppins";
    color: black;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px;
    margin-left: 10%;
}


@media screen and (max-width:800px) 
{

    .about-main-container
    {
        margin-left: 0;
        padding-left: 0;
    }

    .about-section-two-container
    {
        flex-direction: column; 
        margin-left: 0;
        padding-left: 0;
    }

    .about-section-two-left
{
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    padding-left: 0;
    flex:1;
    max-width:100%;
}

.about-section-two-right
{
    flex:1;
    margin-left: 0%;
}

.about-section-2-title
{
    max-width: 95%;
    font-size: 24px;
    margin-left: 5%;
    text-align: center;
}

.about-section-2-hero
{
    max-width: 90%;
    margin-left: 5%;
}

.about-section-2-hero-inner {
    display: inline-block;
    text-align: center;
    margin-top: 5%;
    font-family: "Playfair Display";
    font-weight: 600;
    font-size: 12px;
    padding: 10px 80px 10px 80px;
    margin: 0;
    max-width: 100%;
    margin-top: 5%;
    border-radius: 60px;
    border-style: solid;
    border-width: 1px;
    border-color: #B3863B;
    font-style: italic;
    color: black;
}
.about-section-2-body
{
    max-width: 95%;
    margin-left: 5%;
}

.about-section-one-title
{
    margin-top: 20%;
}

.about-section-one-container
{
    background-size: cover;
    height:500px;
}
}