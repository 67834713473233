@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css");

.footer-container-wrapper {
  width: 100%;
  background-color: #F5F5F5;
}

.footer-flex-container {
  display: flex;
  width: 80%;
  margin-left: 10%;
  flex-direction: column;
}

.footer-logo-flex {
  display: flex;
  margin-top: 2%;
  gap: 1%;
  align-items: center;
}

.footer-lower-container {
  display: flex;
  flex-direction: row;
  height: auto;
  padding-bottom: 2%;
  border-bottom: solid 1px #E0DCDC;
}

.footer-section-left {
  font-size: 16px;
  width: 50%;
  text-align: left;
  color: #595959;
  font-family: "Poppins";
  font-weight: 400;
  vertical-align: middle;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-right: solid 1px #E0DCDC;
  flex: 4;
}

.footer-section-mid {
  flex: 2;
  padding-left: 1%;
}

.footer-nav-list {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 0%;
  padding-bottom: 2%;
  border-bottom: solid 1px #E0DCDC;
}

.footer-nav-item a {
text-decoration: none;
color: #595959;
}

.footer-nav-item {
  color: #595959;
}


.footer-section-right {
  flex: 2;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.footer-newsletter-title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
}

.footer-newsletter-text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
}

.footer-links {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  width: 100%;
  height: auto;
  margin-left:auto;
  align-content:space-evenly;
  flex-wrap: wrap;
  margin-bottom: 2%;
  font-family: "Poppins";
  font-size: 14px;
  font-weight:400;
  color:black;
}

.copyright-notice {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: #595959;
  width: 50%;
  margin-bottom: 2%;
}


.social-media-links {
  height: 100%;
  margin-left: auto;
  margin-top: 5%;
  display: flex;
  text-align:left;  
  vertical-align: bottom;
  justify-items:auto;
  gap: 20px;
}

.fa-brands {
  font-size: 24px;
  height: 40px;
  width: 40px;
  margin-right: 2%;
  text-align: center;
  text-decoration: none;
  vertical-align: bottom;
  border-radius: 50%;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
}

/* Facebook */
.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-instagram {
  background: #C49952;
  color: white
}

/* Twitter */
.fa-twitter {
  background: #C49952;
  color: white
}

.fa-tiktok {
  color: white;
  background-color: #C49952
}


@media screen and (max-width: 800px)
{
  .copyright-notice {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 8px;
    color: #595959;
    width: 100%;
    margin-bottom: 2%;
  }

  .footer-section-left
  {
    font-size: 8px;
    padding-bottom:5%;
  }



  .footer-flex-container {
    display: flex;
    width: 90%;
    margin-left: 5%;
    flex-direction: column;
    padding-top:2%;
  }

  .social-media-links
  {
    font-size: 0px;

  }

.footer-nav-list
{
  font-size: 8px;
}

.footer-logo-flex { 
  padding-bottom:5%
}

  .footer-logo-img
  {
    margin-left: 0%;
    width:16px;
    height:16px;
  }

  .footer-logo-text
  {
    margin-left: 0%;
    width: 128px;
  }
}