.testimony-section-main
{
    padding-top: 5%;
    padding-bottom: 5%;
    width:100%;
    min-height:500px;
    background-color: #161110;
}

.testimony-section-flex-container
{
    display: flex;
    width:80%;    
    flex-wrap: wrap;
    margin-left: 10%;
    flex-direction: row;
    min-height:300px;
}

.testimony-section-navbox
{
    flex: 1;
    padding-right: 100px;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-right:1px solid #413C3B;
}



.testimony-section-review-container
{
    flex: 1;
    display: flex;
    margin-top: 2%;
    direction: row;
    overflow:hidden;
}

.testimony-navbox-title
{
    font-family: 'Poppins';    
    align-self: center;
    margin-top: 10%;
    font-weight:600;
    font-size: 26px;
    color:white;
}

.testimony-navbox-body
{
    font-family: 'Poppins';
    font-weight:400;   
    margin-top: 5%; 
    align-self: center;
    font-size: 16px;
    color:#D7D7D7;
}

.testimony-nav-arrow
{
    text-decoration: none;
    background-color: none;
    background: none;
    border-style:none;
    margin-right: 20%;
    cursor:pointer;
}

.testimony-navbox-arrow-container
{
    margin-top: 5%;
}


@media screen and (max-width: 800px)
{
    .testimony-section-navbox
    {
        border: none;
        margin:0;
        padding: 0;
        padding-right: 5%;
        max-width:100%;
    }   

    .testimony-section-review-container
    {
        display: flex;
        max-width:100%;
    }

    .testimony-section-flex-container
    {
        display: block;
        direction: column;
        flex-wrap: wrap;
        max-width:100%;
    }

    .testimony-navbox-body
{
    font-family: 'Poppins';
    font-weight:400;   
    margin-top: 5%; 
    align-self: center;
    font-size: 12px;
    color:#D7D7D7;
}

.testimony-navbox-title
{
    font-family: 'Poppins';    
    align-self: center;
    margin-top: 10%;
    font-weight:600;
    font-size: 18px;
    color:white;
}

.testimony-navbox-arrow-container
{
    text-align: center;
    margin-bottom:5%;
    margin-left: 10%;

}
}