@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url('https://fonts.googleapis.com/css?family=Poppins');

.testimonial-container{
    flex: 1 0 80%;

    min-height:50px;
    max-height:700px;
    width:80%;
    margin-left: 1%;
    margin-right:0;
    flex-direction:column;
    align-items: center;    
    padding-bottom:5%;
    margin-bottom:5%;
}

.testimonial-container:after {
    display:inline-flex;
    min-height:50px;
    max-height:700px;
    width:80%;
    margin-left: 10%;
    border-style:solid;
    border-width:1px;
    border-color:white;
    background-color:black;
    margin-right:20px;
    flex-direction:column;
    align-items: center;    
    padding-bottom:5%;    
    transition: 1.0s;
    margin-bottom:5%;

}

.testimonial-rating-container {
    text-align:right;
    width:auto;
    letter-spacing:8px;
    font-size:16px;    
}


.testimonial-icon {
    width:50px;
    height:50px;
    border-style:solid;
    border-radius:50%;
    overflow:hidden;
}



.testimonial-header {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow:hidden;
    align-items: center; 
    width:100%;
}

.testimonial-title-container {
    flex:3;

    }
    
    .testimonial-rating-container {
    flex:1;
    
    }

.testimonial-icon-container {    
    min-height:64px;
    min-width:64px;
    column-gap:0px;
    margin-left: 5%;
}

.testimonial-underscore
{
    min-height: 1px;
    max-height: 1px;
    width:100%;
    margin-left: 5%;
    background-color: #61626A
}

.testimonial-title {
    justify-content: left; 
    font-family: "Poppins";
    font-weight:600;    
    font-size:16px;
    padding-left:0%;
    padding-right:0%;
    margin-bottom:5%;
    padding-bottom:0;
    width:100%;
    vertical-align: top;
}

.testimonial-more-button {
    justify-content: center;    
    text-decoration:none;
}

.testimonial-more-button:hover {
    text-decoration:underline;
    text-decoration-color: gold;
}

.more-link {
    font-size:16px;
}

.testimonial-body {
    text-align:left;
    font-size:14px;
    margin-left:5%;
    margin-right:5%;
    line-height:30px;
    font-family: "Poppins";
    font-weight:200;
    font-style: italic;   
    color:#87878b; 
}

.testimonial-top {
    display:flex;
    text-align:left;
    width:100%;
    margin-top:5%;
    justify-items:left;
    align-items:normal;
}

.testimonial-job-role {
    font-family: "Poppins";
    font-weight:400;    
    font-size:12px;
    vertical-align:top;
}

.span {
    margin:0;
    padding:0;
}

.checked {
    color:gold;
}

@media screen and (max-width: 800px) {
    
    .testimonial-container{
        display:inline-block;
        max-width:100%;
        border-style:solid;
        border-width:0px;
        border-color:white;
        flex-direction:column;
        padding-bottom:5%;
        margin-bottom:5%;
    }
    
    .testimonial-container:after {
        display:inline-block;
        max-width:100%;
        border-style:solid;
        border-width:0px;
        border-color:white;
        background-color:black;
        padding-bottom:5%;    
        transition: 1.0s;
        margin-bottom:5%;
    
    }


    .testimonial-rating-container {
        text-align:right;
        width:auto;
        letter-spacing:8px;
        font-size:6px;    
    }

    .testimonial-body {
        flex: 1;
        text-align:left;
        font-size:8px;
        min-width:100%;
        margin-left:0%;
        margin-right:0%;
        flex-basis: 100%;
        line-height:30px;
        font-family: "Poppins";
        font-weight:200;
        font-style: italic;   
        color:#87878b; 
    }

    .testimonial-header
    {
        max-width:100%;
        margin-left: -5%;
        flex-basis: 100%;
    }

    .testimonial-underscore
    {
        margin-left: -5%;
    }
}