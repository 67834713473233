.loginPanelContainer 
{
    align-self:center;
    text-align:center;
    width:50%;
    margin-left:25%;
    height:400px;
    border-style:solid;
    border-color:grey;
    background-color:rgb(60,60,60);
    border-radius:12px;
}



.loginTopSpace {
    margin-top: 0%;
}