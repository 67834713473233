.faq-title {
    font-size: 18px;
    margin-bottom: 2%;
    border-style: none;
}


.faq-main {
    border-color: white;
}

.faq-section-1 {
    width: 100%;

    background: rgba(0, 0, 0, 1);
    background-image: url("../../images/image 2.png");
    height: 800px;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
    padding: 0;
}


.faq-section-1-inner {
    padding: 0;
    display: flex;
    height: 600px;
    opacity: 1;
    flex-direction: column;
    justify-content: space-between;
}

.faq-section-2 {
    background-color: rgb(240, 240, 240);
    padding-top: 0;
    margin-top: 0;
}

.faq-section-2-inner {
    width: 80%;
    padding-left: 10%;
}

@media only screen and (max-width: 800px) {
    .faq-section-1 {
        width: 100%;
        background: rgba(0, 0, 0, 1);
        background-image: url("../../images/image 2.png");
        height: 400px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        background-blend-mode: multiply;
        padding: 0;
    }

    .triangle-container {
        visibility: hidden;
        background-color: red;
    }
}