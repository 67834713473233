@import url(https://fonts.googleapis.com/css?family=Poppins);

.NavlistContainer {
    padding-top: 0%;
    height: 100%;
    width: 100%;
    flex:1;
    text-align: right;
    display: inline-flex;
    justify-content:right;
    justify-self: right;
}

.Navlist {
    font-family: "Poppins";
    font-weight:400;
    font-size: 16px;
    width:90%;
    margin-left:auto;      
}

li {
    display: inline-block;    
    margin-left: 5%;
}

a {
    color:white;
    text-decoration: none;
}

a:hover {
    color: orange;
}

.applyBtnNav {
    min-width: 120px;
    min-height: 40px;
    max-height: 60px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight:100;
    letter-spacing: 1.3px;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    display: flexbox;
    padding: 0px;
    padding-left:40px;
    padding-right:40px;
    padding-bottom: 15px;
    padding-top: 15px;
    background: #B3863B; 
  }

  .Navlist-inner {
    width:100%;
  }

.Navlist-inner.active{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
}

.burger-menu {
    display: none; /* Show the burger menu on smaller screens */
  }


/* Media query for responsive design */
@media only screen and (max-width: 768px) {

  .NavlistContainer {
   width:100%;
  }
   .Navlist-inner{
      display: none; /* Hide the regular menu on smaller screens */
    }
  
    .burger-menu {
      display: block; /* Show the burger menu on smaller screens */
      width:auto;
      text-align: right;
      margin-left: 0 auto;
      right:0;
      margin-top: 10px;
      margin-right: 10px;
    }
  
    .Navlist-inner.active {
      display: flex; /* Show the menu when the burger menu is active */
      flex-direction: column;
      position: absolute;
      top: 40px;
      right: 0;
      width:auto;
      background-color: #333;
    }
  
    .Navlist-inner.active a {
      padding-right: 15px;
      width:100%;
      text-align: left;
    }

    li {
        display: block;  
        padding-bottom:10px; 
        text-align: left; 
        margin-left: 0%;
    }

    .burger-bar{
      width: 25px;
      height: 2px;
      background-color: grey;
      margin: 6px 0;
      margin-left: 0 auto;
    }

    .applyBtnNav {
      min-width: 60px;
      min-height: 40px;
      max-height: 60px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight:100;
      letter-spacing: 1.3px;
      color: white;
      border: none;
      cursor: pointer;
      outline: none;
      position: relative;
      display: flexbox;
      padding: 0px;
      padding-left:10px;
      padding-right:10px;
      padding-bottom: 0px;
      margin-left: 0%;
      padding-top: 0px;
      background: #B3863B; 
    }
  }
/*
.{
        position:static;
        font-family:"Arial";
        font-size:14px;
        border-radius: 32px;
        background-color:rgb(255, 215, 0);
        font-weight:bold;
        padding:0;
        padding-left:2%;
        padding-right:2%;
        border-style:none;
        margin-right:5%;
        margin-top:2%;
        margin-bottom:2%;
        vertical-align:center;
        cursor:pointer;
}

.applyBtnNav:hover{
    font-family:"Arial";
    font-size:14px;
    border-radius: 32px;
    background-color:rgb(0, 0, 0);
    color:white;
    font-weight:bold;
    padding:0;
    padding-left:4%;
    padding-right:4%;
    border-style:none;
    margin-top:0%;
    vertical-align:center;
    cursor:pointer;
}
*/

@media only screen and (max-width: 600px) {

    .Navlist {
        font-family: "Poppins";
        font-weight:400;
        font-size: 12px;
        width:90%;
        margin-left:auto;
    }

}