@font-face { font-family: MBFRoyal; src: url('../public/font/MBF\ Royal.ttf') }

*{
  margin:0px;
  padding: 0px;
  box-sizing: border-box;
}

  body {
    max-width: 100vw;
    width:100%;
    min-height: 100vh;
    font-family: sans-serif;
    font-size: 45px;
    position: absolute;
    overflow-x:hidden;     
  }

  body {
    font-size: 30px;
  }

  .App {
    width:100%;
    margin-left:0%;
  }
  .logo {
    justify-self: left;
    height:80px;
    width:80px;
  }

  .logo_name {
    width:220px;
    vertical-align: top;
  }
  .navContainer {    
    width:80%;
  }

.NavContainer {  
  display:flex;
  margin-top:0%;
  margin-left:1%;
  height:80px;  
  width:98%;  
  align-items:center;
  justify-content: space-between;
  position:absolute;
  z-index: 1;
}

.logoContainer
{
  display: flex;
  flex-direction: row;
  width:50%;  
  margin-top: 10px;
  padding-top: 0;
}

.logoText a:hover
{
  color:rgb(235, 161, 161);
}

.logoText
{
  margin-left: 2%;
  flex:1;
  font-family: MBFRoyal;
  font-size: 28px;
  display: flex;
  flex-direction: row;
  margin-top: -1%;
  height: 87px;
  align-items: center;
  text-transform: uppercase;
}



.goldText
{
  color:goldenrod;
}

.logoNameContainer {
  display:flex;
  margin-left:1%;
  justify-content:center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .logo {
    height:48px;
    width:48px;
    margin-top: 0;
    justify-self: flex-start;
  }

  .logoContainer {
    margin-top: 0px;
    padding-top: 0;
    width:70%;

  }

  .logoText
  {
    margin-left: 2%;
    flex:1;
    font-family: MBFRoyal;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    height: 55px;
    align-items: center;
    margin-top: -1%;
    text-transform: uppercase;
  }
}

/*
@media screen and (max-width: 600px) {
  .logo {
    justify-self: left;
    height:22px;
    width:22px;
  }
  .logo_name {
    height:9px;
    width:85px;
    vertical-align: top;
  }

  
}
*/

/*
@media screen and (max-width: 1366px) {
  .NavContainer {  
    display:flex;
    margin-top:1%;
    margin-left:15%;
    height:80px;  
    width:70%;  
  }

  body {
    font-size: 30px;
  }
  .App {
    width:100%;
    margin-left:0%;
  }

  .logo {
    justify-self: left;
    height:80px;
    width:80px;
  }
  .logo_name {
    height:15px;
    width:145px;
  }
  .navContainer {    
    width:80%;
  }
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 20px;
  }
  .App {
    width:100%;
    margin-left:0;
  }
  .logo {
    justify-self: left;
    height:80px;    
    width:80px;
    
  }
  .logo_name {
    height:15px;
    width:145px;
  }
  .navContainer {    
    width:80%;
  }
}

@media screen and (max-width: 768px) {
  .NavContainer {  
    display:flex;
    margin-top:1%;
    margin-left:0;
    height:80px;  
    width:70%;  
  }

  body {
    font-size: 10px;
  }
  .App {
    width:100%;
    margin-left:0%;
  }
  .logo {
    justify-self: left;
    height:64px;
    width:64px;
  }
  .logo_name {
    height:15px;
    width:145px;
  }
  .navContainer {    
    width:90%;
  }
}


@media screen and (max-width: 480px) {
  .logoNameContainer {
    display:flex;
    justify-content:center;
    align-items:normal;
    margin-top:4%;
    margin-left:2%;
  }

  body {
    font-size: 8px;
  }
  .App {
    width:100%;
    margin-left:0;
  }
  .logo{
    justify-self: left;
    height:32px;
    width:32px;
  }
  .logo_name {
    justify-self:left;
    height:10px;
    width:100px;
  }
  .navContainer {    
    width:100%;
    margin-left:0;
  }
}

body {
    background:white;
    color:black;
  }

*/

@media (prefers-color-scheme: dark) {
  body {
    background: black;
    color:white;
  }
}



.applyBtn {
  font-family:"Arial";
  font-size:14px;
  border-radius: 32px;
  background-color:rgb(255, 215, 0);
  font-weight:bold;
  padding:2%;
  padding-left:4%;
  padding-right:4%;
  border-style:none;
  margin-right:5%;
  transition: 1s background-color;
  margin-top:2%;
}

.applyBtn:hover
{
  background-color: linear-gradient(90deg, rgba(255,215,0,1) 0%, rgba(247,232,0,1) 51%, rgba(255,115,0,1) 100%);
}