.feature-container{
    display:inline-flex;
    min-height:320px;
    max-height:320px;
    max-width:280px;
    border-style:solid;
    border-width:1px;
    border-color:gold;
    margin-right:20px;
    flex-direction:column;
    align-items: center;    
    padding-bottom:5%;
    margin-bottom:5%;
}

.feature-container:after {
    display:inline-flex;
    min-height:320px;
    max-height:320px;
    max-width:280px;
    border-style:solid;
    border-width:1px;
    border-color:gold;
    background-color:black;
    margin-right:20px;
    flex-direction:column;
    align-items: center;    
    padding-bottom:5%;    
    transition: 1.0s;
    margin-bottom:5%;

}

.feature-icon {
    width:32px;
    height:32px;
    margin-top:20%;
}

.feature-container:hover{
    background-color:rgb(75, 75, 75);
    transition:1.0s;
}

.feature-icon-container {    
    min-height:64px;
    min-width:64px;
    margin-top:15%;
    border-style:none;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(102,75,0,1) 4%, rgba(158,137,8,1) 100%);
    border-radius:0px 24px 24px 24px;
    row-gap:0px;
}

.feature-icon-container {
    border-radius:0px 24px 24px 24px;
    transition: 0.5s;
}

.feature-container:hover .feature-icon-container {
    border-radius:24px 24px 0px 24px;
    transition:0.5s
}

.feature-title {
    justify-content: center; 
    font-size:18px;
    padding-left:2%;
    padding-right:2%;
}

.feature-more-button {
    justify-content: center;    
    text-decoration:none;
}

.feature-more-button:hover {
    text-decoration:underline;
    text-decoration-color: gold;
}

.more-link {
    font-size:16px;
}

.feature-body {
    font-size:16px;
    margin-left:2%;
    margin-right:2%;
}