.data-record-container {
    display:inline-flex;
    min-width:20%;
    max-width:100%;
    margin-left:2%;
    flex-direction: column;
    text-align:left;
    vertical-align:top;
    font-size:18px;
}

.filter-panel {
    display:inline-block;
    min-width:50%;
    max-width:100%;
    text-align:left;
    font-size:18px;
}


.action-button {    
    border: solid 1px grey;
    width:100px;
    margin-bottom:5%;
    background-color:blue;
}


.delete-record {
    background-color:rgb(150,0,0);
}

.action-container {
    display:flex;
    flex-direction: column;
}